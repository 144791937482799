<template>
  <!-- login form 共通 -->
  <b-container class="py-5">
    <b-card no-body>
      <b-card-body>
        <b-img
          v-if="image"
          v-bind:src="image"
          alt="top-image"
          center
          width="50"
        ></b-img>
        <b-card-title title-tag="h1" class="text-cmain">
          PLR+
          <span v-if="title"><br />{{ title }}</span>
        </b-card-title>
        <b-form v-on:submit.prevent="onFormSubmit">
          <b-form-group>
            <b-input-group v-if="showDomain">
              <b-input-group-prepend is-text>
                <b-icon icon="globe" variant="csub"></b-icon>
              </b-input-group-prepend>
              <b-form-select
                v-model="domain"
                id="domain"
                value-field="domain"
                text-field="domain"
                v-bind:options="domainList"
                required
              >
                <template #first>
                  <b-form-select-option v-bind:value="null" disabled>
                    -- ドメインを選択してください --
                  </b-form-select-option>
                </template>
              </b-form-select>
            </b-input-group>
          </b-form-group>
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend is-text>
                <b-icon icon="person-fill" variant="csub"></b-icon>
              </b-input-group-prepend>
              <b-form-input
                id="user"
                v-model="user"
                v-bind:placeholder="placeholderUser"
                required
              >
              </b-form-input>
            </b-input-group>
          </b-form-group>
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend is-text>
                <b-icon icon="lock-fill" variant="csub"></b-icon>
              </b-input-group-prepend>
              <b-form-input
                id="password"
                v-model="password"
                placeholder="password"
                type="password"
                required
              >
              </b-form-input>
            </b-input-group>
          </b-form-group>
          <b-button type="submit" variant="cmain" class="mt-5" block
            >Login</b-button
          >
        </b-form>
        <div v-if="errMessage" class="text-danger text-center mt-3">
          {{ errMessage }}
        </div>
      </b-card-body>
    </b-card>
  </b-container>
</template>

<script>
export default {
  props: {
    title: { type: String, default: null },
    image: { type: String, default: null },
    showDomain: { type: Boolean, default: false },
    domainList: { default: () => [] },
    placeholderUser: { type: String, default: 'username' },
    errMessage: { type: String, default: null },
  },
  data() {
    return {
      domain: null,
      user: '',
      password: '',
    }
  },
  methods: {
    onFormSubmit() {
      // 親へ入力情報を送る
      const loginData = { user: this.user, password: this.password }
      if (this.showDomain) {
        loginData.domain = this.domain
      }
      this.$emit('submit', loginData)
    },
  },
}
</script>

<style scoped>
.card {
  border: none;
  max-width: 400px;
  margin: 0 auto;
}
.card-title {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 3rem;
  margin-top: 1rem;
  text-align: center;
}
.card-body {
  padding-top: 3rem;
  padding-bottom: 5rem;
}
.input-group {
  margin-bottom: 2rem;
}
.input-group-text {
  background-color: white;
  border: none;
}
.form-control {
  border-right: none;
  border-left: none;
  border-top: none;
}
</style>
