<template>
  <!-- Consumer Login -->
  <login-form
    title="データ取引会員サイト"
    v-bind:show-domain="true"
    v-bind:domain-list="domainList"
    v-bind:err-message="message"
    placeholder-user="user id"
    v-on:submit="login"
  />
</template>

<script>
import LoginForm from '@/components/common/LoginForm'
export default {
  components: {
    LoginForm,
  },
  data() {
    return {
      domainList: [],
      domain: null,
      message: null,
    }
  },
  created() {
    this.getDomainList()
  },
  methods: {
    getDomainList() {
      console.log('* get domain list *')
      this.$axiosDC
        .post('/dc-consumer/get_domain_list')
        .then((response) => {
          console.log(response)
          this.domainList = response.data.domainList
        })
        .catch((error) => {
          console.log(error)
          this.message = 'ドメインリストの取得に失敗しました。'
        })
    },
    login(loginData) {
      console.log('** consumer login **')
      this.message = null
      this.$axiosDC
        .post('/dc-consumer/consumer_login', {
          domain: loginData['domain'],
          userId: loginData['user'],
          password: loginData['password'],
        })
        .then((response) => {
          console.log(response)
          sessionStorage.setItem('consumerName', response.data.consumerName)
          this.$router.push({ name: 'contracted-list' })
        })
        .catch((error) => {
          console.log(error)
          if (error.response && error.response.status == 401) {
            this.message = 'ドメイン/ユーザID/パスワードのいずれかが不正です。'
          } else {
            this.message = 'ログインに失敗しました。'
          }
        })
    },
  },
}
</script>
